<template>
  <component
    :is="as"
    :to="isRouterLink && target"
    :href="isRegularLink && target"
    :disabled="disabled"
    :class="cssClasses.buttonClasses"
    :diameter="diameter"
    @click.prevent.stop="click"
    ref="button"
    :style="cssStyles.button"
    :event="!isDisabled && isRouterLink ? 'click' : null"
    :tabindex="isDisabled ? -1 : 0"
    :aria-hidden="isDisabled"
  >
    <VueText
      as="span"
      :class="cssClasses.iconClasses"
      v-if="!!iconName"
      :style="cssStyles.iconHolder"
    >
      <VueIcon
        :iconColor="iconColor"
        :width="iconSize.width"
        :height="iconSize.height"
        :iconName="iconName"
      />
    </VueText>
    <VueText as="span" :class="cssClasses.textClasses">
      <slot />
    </VueText>
  </component>
</template>
<script>
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import VueText from '@/components/shared/VueText/VueText.vue';

import { COMPONENT_SHAPES, COMPONENT_SIZES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';

export default {
  name: 'BrandButton',
  props: {
    shape: {
      type: String,
      default: COMPONENT_SHAPES.rectangle,
    },
    diameter: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
    contentAlignment: {
      type: String,
      default: FLEX_JUSTIFIES.center,
    },
    as: {
      type: String,
      default: 'button',
    },
    target: {
      type: [String, Object],
    },
    outlined: {
      type: Boolean,
    },
    size: {
      type: String,
      default: COMPONENT_SIZES.medium,
    },
    isBlock: {
      type: Boolean,
      default: true,
    },
    iconName: {
      type: String,
    },
    iconSize: {
      type: Object,
      default: () => ({
        width: 18,
        height: 18,
      }),
    },
    iconColor: {
      type: String,
    },
    bottomFixed: {
      type: Boolean,
    },
    hideIconOnSmallScreen: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueIcon,
    VueText,
  },
  computed: {
    shapes() {
      return COMPONENT_SHAPES;
    },
    cssClasses() {
      const textClasses = [this.$style[`button-text`]];
      const buttonClasses = [
        this.$style.button,
        this.$style[`button-content-${this.contentAlignment}`],
      ];

      buttonClasses.push(this.$style[`button-${this.shape}`]);

      if (this.outlined) {
        if (this.bottomFixed) {
          buttonClasses.push(this.$style[`button-outlined-bottom`]);
        } else {
          buttonClasses.push(this.$style[`button-outlined`]);
        }

        buttonClasses.push(this.$style.outlined);
        if (!this.isDisabled) {
          buttonClasses.push(this.$style[`outlined-white-bg`]);
          textClasses.push(this.$style[`text-black`]);
        } else {
          textClasses.push(this.$style[`text-white`]);
        }
      } else {
        textClasses.push(this.$style[`text-white`]);
      }

      buttonClasses.push(this.$style[`button-${this.size}`]);

      if (this.disabled) {
        buttonClasses.push(this.$style.disabled);
      }

      const iconClasses = [this.$style['icon-holder']];

      this.hideIconOnSmallScreen && iconClasses.push(this.$style['hide-on-small-screen']);

      return { buttonClasses, iconClasses, textClasses };
    },
    actualWidth() {
      return this.$refs.button ? `${this.$refs.button.getBoundingClientRect().width}px` : null;
    },
    isDisabled() {
      return this.disabled;
    },
    isRouterLink() {
      return this.as === 'router-link';
    },
    isRegularLink() {
      return this.as === 'a';
    },
    cssStyles() {
      const button = {
        width: this.diameter
          ? `${this.diameter}px`
          : this.actualWidth
          ? `${this.actualWidth}px`
          : this.isBlock
          ? '100%'
          : 'initial',
        height: this.diameter ? `${this.diameter}px` : 'auto',
      };
      const iconHolder = {
        'padding-right': this.diameter ? 0 : '8px',
      };
      return { button, iconHolder };
    },
  },
  methods: {
    click(e) {
      if (this.isRegularLink && this.isDisabled) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (this.isRouterLink) {
        e.stopPropagation();
      }

      if (!this.isDisabled) {
        if (this.isRegularLink && this.target) {
          location = this.target;
        } else {
          this.$emit('click', e);
        }
      }
    },
  },
};
</script>

<style module lang="scss">
@import '~@/styles/styles';

.button {
  display: flex;
  align-items: center;
  text-align: center;
  padding: palette-space-level(3);
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  text-transform: $button-text-transform;
  min-width: $button-min-width;
  min-height: $button-min-height;
  position: relative;
  overflow: hidden;
  font-family: $button-font-family;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  border-radius: $button-border-radius;
  box-shadow: $button-shadow;
  transition: $button-transition;
  transition-property: box-shadow, background-color;
  height: $button-height;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  text-decoration: $button-text-decoration;
  outline: none;
  border: none;
  position: relative;
  overflow: visible;
  background-repeat: no-repeat !important;
  &:after {
    content: '';
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: 2px;
    left: 2px;
    background: linear-gradient(to left, #d20051 0%, #ffa300 100%);
    z-index: -1;
    border-radius: inherit;
  }
  span {
    pointer-events: none;
  }

  &-text {
    display: flex;
    align-items: center;
    color: palette-color-level('white', '100');
  }
  @include bg-linear-gradient(
    to left,
    palette-color-level('fushia', '20') 0%,
    palette-color-level('yellow', '30') 100%
  );

  &:active {
    box-shadow: $button-active-shadow;
    text-decoration: $button-hover-text-decoration;
  }

  // &:hover {
  //   text-decoration: $button-hover-text-decoration;
  // }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    opacity: $button-disabled-opacity;
    cursor: not-allowed;
    box-shadow: none;
    background: palette-color-level('grey', '20');
    color: #ffffff !important;

    &:after {
      display: none;
    }
    // &:hover {
    //   box-shadow: none;
    // }
  }
}
.button-rounded {
  border-radius: palette-radius-level(11);
}

.button-circle {
  border-radius: 50%;
}

.module {
  width: 100%;
}
.outlined-white-bg {
  background: #ffffff !important;
}

.button-outlined {
  &:after {
    content: '';
    position: absolute;
    top: -2px;
    bottom: -2px;
    right: -2px;
    left: -2px;
    background: linear-gradient(to left, #d20051 0%, #ffa300 100%);
    z-index: -1;
    border-radius: inherit;
  }
}
.button-outlined-bottom {
  &:after {
    content: '';
    position: absolute;
    top: -2px;
    bottom: -2px;
    right: -2px;
    left: -2px;
    background: none;
    z-index: -1;
    border-radius: inherit;
  }
}

.module-outlined {
  background-color: transparent;
}

.text-white {
  color: #ffffff !important;
}
.text-black {
  color: #000000 !important;
}

@each $variation, $values in $button-size-variations {
  .button-#{$variation} {
    padding: map-get($values, 'padding');
    font-size: map-get($values, 'font-size');
    border-radius: map-get($values, 'border-radius');
  }
}

@each $variation, $alignment in $palette-flex-alignments {
  .button-content-#{$alignment} {
    justify-content: $alignment;
  }
}
.icon-holder {
  display: inline-flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 375px) {
    &.hide-on-small-screen {
      display: none;
    }
  }
}
</style>
