import RouteBase from '@/router/routes/RouteBase.js';

class RoutesDoAndWin {
  subFolderPath = '/doAndWin';
  isPublic = false;

  DoAndWinHome = new RouteBase({
    name: 'do-and-win-home',
    path: '',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'DoAndWinHome',
    isPublic: this.isPublic,
    meta: {
      icon: 'wave',
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Yap Kazan',
      },
    },
  });

  TakeQuiz = new RouteBase({
    name: 'take-quiz',
    path: 'take-quiz',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'TakeQuiz',
    isPublic: this.isPublic,
    meta: {
      hideBottomBar: true,
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Yap Kazan',
      },
    },
  });
}

export default new RoutesDoAndWin();
