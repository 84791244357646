import RouteBase from '@/router/routes/RouteBase.js';

class RoutesStatic {
  RegulationDetails = new RouteBase({
    name: 'regulation-details',
    path: 'regulation-details',
    componentFileName: 'RegulationDetails',
    componentFolderPath: '/static',
    isPublic: false,
    meta: {
      appbarConfig: { isCollapsable: false, hasBackButton: true, title: 'Regülasyon Detayları' },
    },
  });

  HealthWarnings = new RouteBase({
    name: 'health-warnings',
    path: 'health-warnings',
    componentFileName: 'HealthWarnings',
    componentFolderPath: '/static',
    isPublic: false,
    meta: {
      appbarConfig: { isCollapsable: false, hasBackButton: true, title: 'Sağlık Uyarıları' },
    },
  });
  FrequentlyAskedQuestions = new RouteBase({
    name: 'faq',
    path: 'faq',
    componentFileName: 'FrequentlyAskedQuestions',
    componentFolderPath: '/static',
    isPublic: false,
    meta: {
      appbarConfig: { isCollapsable: false, hasBackButton: true, title: 'Sıkça Sorulan Sorular' },
    },
  });
  CookiePolicy = new RouteBase({
    name: 'cookie-policy',
    path: 'cookie-policy',
    componentFileName: 'CookiePolicy',
    componentFolderPath: '/static',
    isPublic: false,
    meta: {
      appbarConfig: { isCollapsable: false, hasBackButton: true, title: 'Çerez Bildirimi' },
    },
  });
}

export default new RoutesStatic();
