<template>
  <div class="app-wrapper">
    <SplashScreen v-if="showSplash" />
    <VueOverlay v-if="!showSplash && getOverlayVisibilityStatus" />
    <router-view v-if="!showSplash" />
    <BrandErrorModal />
  </div>
</template>

<script>
import BrandErrorModal from '@/components/brand/Modals/BrandModal/BrandErrorModal.vue';
import { mapGetters } from 'vuex';
import VueOverlay from '@/components/shared/VueOverlay/VueOverlay.vue';
import SplashScreen from '@/components/brand/SplashScreen/SplashScreen.vue';
import { isPwaModeEnabled } from '@/utils/helpers';
import StorageHelper from '@/utils/storageHelper';

export default {
  name: `App`,
  components: {
    VueOverlay,
    SplashScreen,
    BrandErrorModal,
  },
  data() {
    return {
      showSplashScreen: true,
    };
  },
  computed: {
    ...mapGetters('app', ['getOverlayVisibilityStatus']),
    ...mapGetters('auth', ['isAuthenticated']),
    showSplash() {
      let logoutFlag = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_USER_LOGOUT_FLAG,
      }).get();
      return isPwaModeEnabled() && this.showSplashScreen && !this.isAuthenticated && !logoutFlag;
    },
  },
  watch: {
    //** Watch overlay state for toggle body scroll */
    getOverlayVisibilityStatus: () => {
      this && this.getOverlayVisibilityStatus
        ? (document.documentElement.style.overflow = 'hidden')
        : (document.documentElement.style.overflow = 'initial');

      return false;
    },
  },
  beforeMount() {
    this.rotateScreen();
    document.title = process.env.VUE_APP_PROJECT_NAME;
    this.cookielawScripts();
  },
  methods: {
    async loadScript(src, attributes = {}) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;

        for (const attr in attributes) {
          script.setAttribute(attr, attributes[attr]);
        }

        script.onload = resolve;
        script.onerror = reject;

        document.head.appendChild(script);
      });
    },
    createOptanonWrapperFunction() {
      const script = document.createElement('script');
      script.textContent = 'function OptanonWrapper() {}';
      document.head.appendChild(script);
    },
    cookielawScripts() {
      const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
      const isDevOrQa = !!(apiBaseUrl.includes('qa') || apiBaseUrl.includes('dev'));
      const dataDomainScript = `1255f79f-3c27-4484-adb2-318896c12a33${isDevOrQa ? '-test' : ''}`;
      const scriptUrl = `https://cdn.cookielaw.org/consent/1255f79f-3c27-4484-adb2-318896c12a33${
        isDevOrQa ? '-test' : ''
      }/OtAutoBlock.js`;

      this.loadScript(scriptUrl);

      this.loadScript('https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', {
        'data-document-language': 'true',
        'data-domain-script': dataDomainScript,
        charset: 'UTF-8',
      });

      this.createOptanonWrapperFunction();
    },
    rotateScreen() {
      const body = document.body;
      body.classList.forEach(item => body.classList.remove(item));
      switch (window.orientation) {
        case 90:
          body.classList.add('rotation90');
          break;
        case -90:
          body.classList.add('rotation-90');
          break;
        default:
          body.classList.add('portrait');
          break;
      }
    },
  },

  mounted() {
    setTimeout(() => {
      this.showSplashScreen = false;
    }, 3000);
    window.addEventListener('orientationchange', this.rotateScreen);
  },
};
</script>

<style lang="scss">
@import '~@/styles/reset';

.app-wrapper {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex: 1;
  .is-locked {
    overflow: hidden;
  }
  > [class^='VueContentHolder_content-holder'] {
    overflow: auto;
  }
}
</style>
