import { HttpService } from '@/services/HttpService';

class User {
  subdomain = '/user';

  /*
    Get User Detail model for insider 
  */
  getUserDetail() {
    return HttpService.get(`${this.subdomain}/getdetails`);
  }
  getUserMobileNo() {
    return HttpService.get(`${this.subdomain}/getmobileno`);
  }
  /*
    Get User Virtual visit model
  */
  getVirtualVisit() {
    return HttpService.get(`${this.subdomain}/getvirtualvisit`);
  }
  /*
    Get chat bot model
  */
  getChatbotModel() {
    return HttpService.get(`${this.subdomain}/getchatbot`);
  }
  /*
    get chat bot data
  */
  getChatbotData() {
    return HttpService.get(`${this.subdomain}/getchatbotdata`);
  }
  /*
    get user messages
  */
  getUserMessages(currentPage, lastId) {
    return HttpService.get(`${this.subdomain}/messages?startPage=${currentPage}&lastId=${lastId}`);
  }
  /*
    delete user message by id
  */
  deleteUserMessage(messageId) {
    return HttpService.delete(`${this.subdomain}/messages/${messageId}`);
  }
  /*
    get user messages
  */
  setUserCookieSettings(cookieSetting) {
    return HttpService.post(`${this.subdomain}/cookie/upsert`, { ...cookieSetting });
  }

  /*
    get user visit and order days
  */
  getRouteDays() {
    return HttpService.get(`${this.subdomain}/getroutedays`);
  }

  getRosetteInfo() {
    return HttpService.get(`${this.subdomain}/getrosetteinfo`);
  }

  getRosetteRewardStatu() {
    return HttpService.get(`${this.subdomain}/getrosetteRewardStatu`);
  }

  setRosetteRewardPoint() {
    return HttpService.post(`${this.subdomain}/setrosetteRewardPoint`);
  }

  newsessionforChatbot() {
    return HttpService.post(`${this.subdomain}/chatbot/newsession`);
  }

  getCertificates() {
    return HttpService.get(`${this.subdomain}/certificates`);
  }

  getQRCode() {
    return HttpService.get(`${this.subdomain}/generateQrCode`);
  }
}

export default new User();
