export const AppDefaultState = () => {
  return {
    config: {
      api: {
        baseURL: process.env.VUE_APP_API_BASE_URL,
      },
    },
    appbarConfig: {},
    defaultMessages: {},
    redirectTo: null,
    cookieConsentVersion: '',
    isOverlayVisible: false,
    isOverlayEnabled: true,
    isMenuOpened: false,
    showBottomBar: true,
    modalConfig: {},
    errorModalConfig: {},
    statusModalConfig: {},
    menuItems: {},
    currentMenuItems: {},
    overflow: 'auto',
    showModalOnFunPage: false,
    searchedText: '',
    customerDetail: {},
    customerPoints: null,
    backFunction: null,
    headTitle: null,
    cookieSettings: null,
    addedToHomeScreenStatus: null,
    deviceType: null,
    virtualVisit: {
      url: null,
    },
    activeNavItem: '',
    location: '',
    qrResponse: {
      rackCount: null,
      customerName: '',
      customerCode: '',
    },
    racks: [],
    onCloseClick: false,
    otpCategoryStatuses: null,
    lastPosition: 0,
    contentTypes: [],
    selectedContentType: {
      contentTypeId: 0,
      contentName: 'Tüm İçerikler',
    },
    feeds: [],
    notificationPopupStatus: false,
    activeDowinCount: 0,
    vvBadge: false,
    qrViewStatus: false,
    isNPSSurveyFinished: false,
    changedPosToken: '',
    rosettes: [],
    brandSideBarLoadCount: 0,
    feedTaskDetail: {},
    popups: {},
    isRosetteRewardStatu: false,
    activeNotificationCount: 0,
    notificationsVisibility: false,
    mainPageBackgroundImage: null,
  };
};
