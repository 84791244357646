import { HttpService } from '@/services/HttpService';
import StorageHelper from '@/utils/storageHelper';
import { store } from '@/store/store';
import OVERLAY_EXCLUDED_URLS from '@/services/HttpService/overlayExcludedUrls';

const setupRequestInterceptor = () => {
  const isPwaModeEnabled = store.getters['app/getAddedToHomeScreenStatus'];
  const osType = store.getters['app/getDeviceType'];
  HttpService.interceptors.request.use(
    config => {
      let requestObject = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer`,
          addToHomeScreen: isPwaModeEnabled,
          osType: osType,
        },
      };

      if (
        HttpService &&
        HttpService.store &&
        HttpService.store.state &&
        HttpService.store.state.auth
      ) {
        if (store.state.app.isOverlayEnabled && !OVERLAY_EXCLUDED_URLS.includes(config.url))
          store.dispatch('app/setOverlayVisibility', true);
        let token =
          new StorageHelper({ id: process.env.VUE_APP_STORAGE_TOKEN_KEY }).get() ||
          HttpService.store.state.auth.accessToken;
        const reqHeaders = { ...config.headers };

        if (token) {
          reqHeaders.Authorization = `Bearer ${token}`;
        }
        if (config.headers.Authorization === 'No Auth') {
          delete reqHeaders.Authorization;
        }
        requestObject = {
          ...config,
          headers: {
            ...reqHeaders,
            addToHomeScreen: isPwaModeEnabled,
            osType: osType,
          },
        };
      }

      return requestObject;
    },

    error => {
      store.dispatch('app/setOverlayVisibility', false);
      return Promise.reject(error);
    },
  );
};
export default setupRequestInterceptor;
