<template>
  <component
    :key="renderKey"
    :is="as"
    :class="cssClasses.modalWrapperClasses"
    v-if="mergedConfig.isModalOpened || false"
  >
    <a v-if="!mergedConfig.noClose" @click="closeModal" :class="[cssClasses.modalCloseClasses]">
      <VueIcon
        iconName="IconCross"
        iconColor="#FFF"
        :width="getIcon.cross.width"
        :height="getIcon.cross.height"
      ></VueIcon>
    </a>
    <div :class="cssClasses.modalContentClasses">
      <div :class="cssClasses.modalContentWrapperClasses">
        <div class="login-error">
          <div>
            <VueIcon
              iconName="IconWarn"
              :width="getIcon.warn.width"
              :height="getIcon.warn.height"
              color="#e5472d"
            />
          </div>
          <div>
            <VueText v-if="!mergedConfig.noTitle" sizeLevel="8" weightLevel="3">Üzgünüz!</VueText>
            <VueText sizeLevel="6" weightLevel="2">{{ mergedConfig.modalText }}</VueText>
          </div>
        </div>
      </div>
      <div :class="cssClasses.modalButtonWrapperClasses">
        <BrandButton @click="clickFirstButton" :size="sizes.xxLarge">{{
          mergedConfig.firstButtonText
        }}</BrandButton>
      </div>
    </div>
  </component>
</template>
<script>
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import { mapGetters } from 'vuex';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';

export default {
  name: 'BrandErrorModal',
  components: {
    VueIcon,
    BrandButton,
    VueText,
  },
  props: {
    as: {
      type: String,
      default: 'div',
    },
  },
  data() {
    return {
      renderKey: 0,
      modalConfig: {
        modalType: 'error',
        contentAlignment: FLEX_JUSTIFIES.center,
        textAlignment: FLEX_JUSTIFIES.center,
        alertType: 'error',
        firstButtonText: 'KAPAT',
      },
    };
  },
  watch: {
    mergedConfig: {
      handler: function() {
        this.renderKey += 1;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    mergedConfig() {
      return { ...this.modalConfig, ...this.getErrorModalConfig };
    },
    cssClasses() {
      const modalWrapperClasses = [this.$style[`modal-wrapper`]];
      const modalContentClasses = [this.$style[`modal-content`]];

      const modalContentWrapperClasses = [
        this.$style[`modal-content-wrapper`],
        this.$style[`modal-content-${this.mergedConfig.contentAlignment}`],
      ];
      const modalIconWrapper = [this.$style[`modal-content-icon`]];
      const modalCloseClasses = [this.$style[`modal-close`]];
      const modalButtonWrapperClasses = [this.$style[`modal-button-wrapper`]];

      if (this.mergedConfig.textAlignment) {
        modalContentWrapperClasses.push(
          this.$style[`modal-content-text-${this.mergedConfig.textAlignment}`],
        );
      }

      return {
        modalWrapperClasses,
        modalCloseClasses,
        modalContentClasses,
        modalIconWrapper,
        modalButtonWrapperClasses,
        modalContentWrapperClasses,
      };
    },
    getIcon() {
      const { apply, cross, warn } = ICON_VARIABLES;
      return { cross, warn, apply };
    },
    sizes() {
      return COMPONENT_SIZES;
    },
    ...mapGetters('app', ['getErrorModalConfig']),
    isRegularLink() {
      return this.as === 'a';
    },
    getAlertName() {
      switch (this.mergedConfig.alertType) {
        case 'error':
          return 'IconError';
        case 'warn':
          return 'IconWarn';
        default:
          return 'IconApply';
      }
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
  },
  methods: {
    closeModal() {
      this.$store.dispatch('app/setErrorModalConfig', {});
    },
    clickFirstButton() {
      if (this.mergedConfig.firstButtonFunction) {
        this.mergedConfig.firstButtonFunction();
      } else {
        this.closeModal();
      }
    },
  },
};
</script>
<style module lang="scss">
.modal {
  &-wrapper {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: palette-space-level('20');
    z-index: 40;
    top: 0;
    left: 0;
    object-fit: contain;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.7);
  }
  &-content {
    width: 100%;
    max-width: 500px;
    overflow: hidden; // Alt radius gorunmemesi durumu icin
    margin: auto;
    z-index: 1;
    background-color: palette-color-level('white', '100');
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    word-break: break-word;

    &-text {
      &-center {
        text-align: center;
        justify-content: center;
      }
    }
    &-wrapper {
      display: flex;
      padding: palette-space-level('20');

      &-nospace {
        padding: 0 !important;
      }
    }
    @each $variation, $alignment in $palette-flex-alignments {
      &-#{$alignment} {
        align-items: $alignment;
      }
    }
    &-icon {
      padding: 20px;
    }
  }
  &-close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  &-button-wrapper {
    display: flex;
    padding: 2px;
  }
}

.modalTitle {
  margin-top: palette-space-level('20');
}
</style>
<style scoped lang="scss">
.login-error {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  div {
    &:last-child {
      flex-grow: 1;
      padding-left: 25px;
      display: flex;
      flex-direction: column;
      p {
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
