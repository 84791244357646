import { AUTH_MUTATION_TYPES } from '@/store/auth/mutations';
import StorageHelper from '@/utils/storageHelper';

export const AuthActions = {
  setAuthData({ commit }, data) {
    try {
      if (data && data.token) {
        const { token, refreshToken, user, pos } = data;

        if (token) {
          commit(AUTH_MUTATION_TYPES.SET_ACCESS_TOKEN, token);
        }
        if (refreshToken && refreshToken.token) {
          commit(AUTH_MUTATION_TYPES.SET_REFRESH_TOKEN, refreshToken.token);
        }
        if (user) {
          commit(AUTH_MUTATION_TYPES.SET_USER_MODEL, user);
        }
        if (pos) {
          const posArr = Array.isArray(pos) ? pos : [pos];

          if (posArr.length === 1) {
            commit(AUTH_MUTATION_TYPES.SET_SELECTED_POS, posArr[0]);
          }
        }
      } else {
        commit(AUTH_MUTATION_TYPES.SET_ACCESS_TOKEN, null);
        commit(AUTH_MUTATION_TYPES.SET_USER_MODEL, null);
        commit(AUTH_MUTATION_TYPES.SET_SELECTED_POS, null);
        new StorageHelper({ id: process.env.VUE_APP_STORAGE_REFRESH_TOKEN_KEY }).remove();
        new StorageHelper({ id: process.env.VUE_APP_STORAGE_TOKEN_KEY }).remove();
        new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).remove();
        new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_POS_KEY }).remove();
      }
    } catch (e) {
      commit(AUTH_MUTATION_TYPES.SET_ACCESS_TOKEN, null);
      commit(AUTH_MUTATION_TYPES.SET_USER_MODEL, null);
      commit(AUTH_MUTATION_TYPES.SET_SELECTED_POS, null);
      new StorageHelper({ id: process.env.VUE_APP_STORAGE_REFRESH_TOKEN_KEY }).remove();
      new StorageHelper({ id: process.env.VUE_APP_STORAGE_TOKEN_KEY }).remove();
      new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).remove();
      new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_POS_KEY }).remove();
    }
  },
  setUserModel({ commit }, user) {
    try {
      if (user) {
        commit(AUTH_MUTATION_TYPES.SET_USER_MODEL, user);
      } else {
        commit(AUTH_MUTATION_TYPES.SET_USER_MODEL, null);
      }
    } catch (e) {
      commit(AUTH_MUTATION_TYPES.SET_USER_MODEL, null);
    }
  },
  setAccessToken({ commit }, token, refreshToken) {
    try {
      commit(AUTH_MUTATION_TYPES.SET_ACCESS_TOKEN, token);
      commit(AUTH_MUTATION_TYPES.SET_REFRESH_TOKEN, refreshToken);
    } catch (e) {
      commit(AUTH_MUTATION_TYPES.SET_ACCESS_TOKEN, null);
      commit(AUTH_MUTATION_TYPES.SET_REFRESH_TOKEN, null);
    }
  },
  setUserDetails({ commit }, userDetails) {
    try {
      if (userDetails) {
        commit(AUTH_MUTATION_TYPES.SET_USER_DETAILS, userDetails);
      } else {
        commit(AUTH_MUTATION_TYPES.SET_USER_DETAILS, null);
      }
    } catch (e) {
      commit(AUTH_MUTATION_TYPES.SET_USER_DETAILS, null);
    }
  },
  setAccountType({ commit }, accountType) {
    commit(AUTH_MUTATION_TYPES.SET_ACCOUNT_TYPE, accountType);
  },
  async setPosList({ commit }, list) {
    try {
      commit(AUTH_MUTATION_TYPES.SET_POS_LIST, list);
    } catch (e) {
      commit(AUTH_MUTATION_TYPES.SET_POS_LIST, null);
    }
  },

  async revokeToken({ commit }) {
    try {
      commit(AUTH_MUTATION_TYPES.SET_ACCESS_TOKEN, null);
    } catch (e) {
      commit(AUTH_MUTATION_TYPES.SET_ACCESS_TOKEN, null);
    }
  },
  setAfterLogin: ({ commit }, status) => commit(AUTH_MUTATION_TYPES.SET_AFTER_LOGIN, status),
};
