import { HttpService } from '@/services/HttpService';

class Account {
  subdomain = '/account';

  /**
   * Updates user profile details.
   *
   * @param {number} {Required} id
   * @param {string} {Required} customerCode
   * @param {string} {Required} name
   * @param {string} {Required} surname
   * @param {string} {Required} phoneNumber
   * @param {string} {Required} email
   * @param {string} {Required} gender
   * @param {number} {Required} cityId
   * @param {string} {Required} cityName
   * @param {string} {Required} birthDate
   * @param {boolean} {Required} kvkk
   * @param {string} {Required} customerName
   * @param {string} {Required} customerAddress
   * @param {string} {Required} password
   * @returns {Promise}
   */
  updateProfile(userProfile) {
    return HttpService.put(`${this.subdomain}/profile`, {
      userProfile,
    });
  }
  changedPos() {
    return HttpService.post(`${this.subdomain}/changepos`);
  }
  uploadProfilePhoto(formData) {
    return HttpService.post(`${this.subdomain}/profilephoto`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  /**
   * Returns user profile details with the given id
   *
   * @param {number} {Required} id
   * @returns {Promise}
   */
  getProfileByUserId(id) {
    return HttpService.get(`${this.subdomain}/profile?userid=${id}`);
  }

  /**
   * Login with customer(pos) code and password.
   *
   * @param {string} {Required} phoneNumber
   * @param {string} {Required} password
   * @returns {Promise}
   */
  loginWithCustomerCode(customerCode, password) {
    return HttpService.post(`${this.subdomain}/loggedin/customer`, { customerCode, password });
  }

  /**
   * Login with mobile phone number & password.
   *
   * @param {string} {Required} phoneNumber
   * @param {string} {Required} password
   * @returns {Promise}
   */
  loginWithPhoneNumber(phoneNumber, password) {
    return HttpService.post(`${this.subdomain}/loggedin/phone`, { phoneNumber, password });
  }

  /**
   * Login with customer tax number & paasword.
   *
   * @param {string} {Required} taxNumber
   * @param {string} {Required} password
   * @returns {Promise}
   */
  loginWithVKN(taxNumber, password) {
    return HttpService.post(`${this.subdomain}/loggedin/vkn`, { taxNumber, password });
  }

  /**
   * Trigger an SMS code is sent to the phone number
   *
   * @param {string} {Required} phoneNumber
   * @returns {Promise}
   */
  sendSmsForChangePassword(phoneNumber) {
    return HttpService.post(`${this.subdomain}/password`, { phoneNumber });
  }
  /**
   * Send the SMS Code received with phone number
   *
   * @param {string} {Required} phoneNumber
   * @param {string} {Required} smsCode
   * @returns {Promise}
   */
  sendSmsCodeWithPhoneNumber(smsCode, phoneNumber) {
    return HttpService.post(`${this.subdomain}/smscode`, { smsCode, phoneNumber });
  }
  /**
   * Set password
   *
   * @param {string} {Required} smsCode
   * @param {string} {Required} newPassword
   * @param {string} {Required} newPasswordRepeat
   * @returns {Promise}
   */
  setPassword(smsCode, newPassword, newPasswordRepeat) {
    return HttpService.put(`${this.subdomain}/password`, {
      smsCode,
      newPassword,
      newPasswordRepeat,
    });
  }
  /**
   * Set new password for expired password
   *
   * @param {string} {Required} password
   * @returns {Promise}
   */
  updateExpiredPassword(password) {
    return HttpService.post(`${this.subdomain}/expirypassword`, {
      password,
    });
  }
  /**
   * Select pos after authentication with mobile phone number.
   *
   * @param {number} {Required} posId
   * @param {number} {Required} userId
   * @param {string} {Required} name
   * @param {string} {Required} surname
   * @param {string} {Required} phoneNumber
   * @returns {Promise}
   */
  selectedPos({ posId, userId, name, surName, phoneNumber }) {
    const surname = surName;
    return HttpService.post(`${this.subdomain}/selectedpos`, {
      posId,
      userId,
      name,
      surname,
      phoneNumber,
    });
  }

  loginPmtep(userToken) {
    return HttpService.post(`${this.subdomain}/loggedin/pmtep`, {
      Token: userToken,
    });
  }
  validateStaff(confirmed) {
    return HttpService.post(`${this.subdomain}/staffvalidation`, { isValidate: confirmed });
  }
  getPartnerCodes() {
    return HttpService.post(`${this.subdomain}/getpartnercodes`, {});
  }
}

export default new Account();
