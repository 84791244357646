export default {
  code: 'tr',
  messages: {
    logoText: 'KanalAktif',
    body: {
      title: 'Hoş geldiniz',
      text: 'Giriş yapmak için aşağıdaki yöntemlerden birini seçin.',
    },
    buttons: {
      phoneText: 'Telefon Numaranız',
      customerNumberText: 'Müşteri Kodunuz',
      vknNumberText: 'VKN ile Giriş',
      firstLoginText: 'İlk Giriş/Şifremi Unuttum',
    },
  },
};
