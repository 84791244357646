import RouteBase from '@/router/routes/RouteBase.js';

class RoutesPmi {
  subFolderPath = '/businessPartners/ulker';
  isPublic = false;

  Home = new RouteBase({
    name: 'ulker-home',
    path: '',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Home',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        icon: 'wave',
        title: 'Ülker',
        hasBackButton: true,
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Ülker',
        },
      ],
    },
  });

  Brand = new RouteBase({
    name: 'brand',
    path: ':brand/:id',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Brand',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Kategori',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Ülker',
          link: '/secure/business-partners/ulker',
        },
        {
          name: 'Krakerler',
        },
      ],
    },
  });

  List = new RouteBase({
    name: 'list',
    path: 'product-price-list',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'List',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Ürün ve Fiyat Listesi',
      },
    },
  });
}

export default new RoutesPmi();
