import RouteBase from '@/router/routes/RouteBase.js';

class RoutesMyWallets {
  subFolderPath = '/myWallets';
  isPublic = false;

  WalletsHome = new RouteBase({
    name: 'wallets-home',
    path: '',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Home',
    isPublic: this.isPublic,
    meta: {
      icon: 'wallet',
      startupConfig: {
        setCustomerDetail: true,
      },
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Cüzdanlarım',
      },
    },
  });
  ConvertScore = new RouteBase({
    name: 'wallets-convert',
    path: 'convert',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'ConvertScore',
    isPublic: this.isPublic,
    meta: {
      icon: 'wallet',
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Cüzdanlarım',
      },
    },
  });
  WalletsDetail = new RouteBase({
    name: 'wallets-detail',
    path: 'detail',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Detail',
    isPublic: this.isPublic,
    meta: {
      icon: 'wallet',
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Cüzdanlarım',
      },
    },
  });
}

export default new RoutesMyWallets();
