import { HttpService } from '@/services/HttpService';

class Common {
  subdomain = '/common';
  /**
   * Get all cities
   * @returns {Promise}
   */
  getCities() {
    return HttpService.get(`${this.subdomain}/city`);
  }
  /**
   * Get all genders
   * @returns {Promise}
   */
  getGenders() {
    return HttpService.get(`${this.subdomain}/gender`);
  }
  /**
   * Get feed by feed id
   * @param {number} {Required} feedId
   * @returns {Promise}
   */
  getFeedById(feedId) {
    return HttpService.get(`${this.subdomain}/feeds/${feedId}`);
  }
  /**
   * Get feedsby feed id
   * @returns {Promise}
   */
  getFeeds(lastIndex, pageSize, contentTypeId) {
    let url = `${this.subdomain}/feeds?StartIndex=${lastIndex}&PageSize=${pageSize}`;

    if (contentTypeId) {
      url = `${url}&ContentTypeId=${contentTypeId}`;
    }

    return HttpService.get(url);
  }
  /**
   * Get menu
   * @returns {Promise}
   */
  getMenu() {
    return HttpService.get(`${this.subdomain}/menu`);
  }
  /**
   * Get ecommerce url
   * @returns {Promise}
   */
  getEcommerceUrl() {
    return HttpService.get(`${this.subdomain}/ecommerceurl`);
  }
  /**
   * Get giftCatalog url
   * @returns {Promise}
   */
  getGiftCatalogLink() {
    return HttpService.get(`${this.subdomain}/giftcataloglink`);
  }
  /**
   * Get get home page upper slider data
   * @returns {Promise}
   */
  getPartnerBanners(customerCode) {
    return HttpService.get(`${this.subdomain}/partnerbanners?CustomerCode=${customerCode}`);
  }

  /**
   * Get weather data
   * @returns {Promise}
   */
  getWeather() {
    return HttpService.get(`${this.subdomain}/weather`);
  }

  /**
   * Get imsakiye data
   * @returns {Promise}
   */
  getImsakiye() {
    return HttpService.get(`${this.subdomain}/imsakiye`);
  }

  /**
   * Get get home page main slider data
   * @returns {Promise}
   */
  getMainBanners() {
    return HttpService.get(`${this.subdomain}/mainsliderbanners`);
  }

  getFunBanners() {
    return HttpService.get(`${this.subdomain}/funsliderbanners`);
  }

  getCookieDetails() {
    return HttpService.get(`${this.subdomain}/getcookiedetails`);
  }

  getRSSList() {
    return HttpService.get(`${this.subdomain}/rsslist`);
  }
  /**
   * Contact
   * @param {number} {Required} subject
   * @param {number} {Required} message
   * @returns {Promise}
   */
  contact(data) {
    return HttpService.post(`/contactus`, data);
  }

  sendMail(data) {
    return HttpService.post(`/sendMail`, data);
  }
  /**
   * Get multi banner data
   * @returns {Promise}
   */
  getMultiBannerData() {
    return HttpService.get(`${this.subdomain}/mainslidermultibanners`);
  }

  getPopups(afterLogin) {
    return HttpService.get(`${this.subdomain}/popup?afterLogin=${afterLogin}`);
  }

  viewPopup(request) {
    return HttpService.post(`${this.subdomain}/viewpopup`, request);
  }

  getEmergencyTrainings() {
    return HttpService.get(`${this.subdomain}/videourl`);
  }

  getNpsSurvey() {
    return HttpService.get(`${this.subdomain}/npssurvey`);
  }

  getMarketingVideoSeries() {
    return HttpService.get(`${this.subdomain}/marketingvideourl`);
  }

  getNpsRosetteDetail() {
    return HttpService.get(`/evalutionsurvey/npsRozette`);
  }

  /**
   * Get feedback platform banner info
   * @returns {Promise}
   */
  getFeedbackPlatformBannerInfo() {
    return HttpService.get(`${this.subdomain}/feedbackplatformbannerinfo`);
  }
}

export default new Common();
