import UserAgentHelper from '@/utils/agentUtils';
import StorageHelper from '@/utils/storageHelper';
import { User } from '@/services/Api/index';

class GtmHelper {
  /**
   * Setup the constructor
   * @param {Object} userDetails  user details insider model
   * @param {Object} cookieSettings   user cookie settings
   */
  constructor(userDetails) {
    this.userDetails = userDetails;
    this.containerID = 'GTM-M2MN5QW';
  }

  removeGTMStartEvent() {
    const filteredDataLayer = window.dataLayer.filter(
      item => !(item['event'] === 'gtm.js' && item.hasOwnProperty('gtm.start')),
    );
    window.dataLayer = filteredDataLayer;
  }

  addGTMStartEvent(dataLayer) {
    const gtmStartEvent = dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

    dataLayer.push(gtmStartEvent);
  }

  initDataLayer(from, to) {
    this.removeGTMStartEvent();

    var _self = this;
    try {
      if (window.dataLayer) {
        _self.pushVirtualPv(window.dataLayer, from, to);
      } else {
        // Google tag manager initialization
        (function(w, d, s, l, i) {
          w[l] = [];
          _self.pushVirtualPv(w[l], from, to);
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
          });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', _self.containerID);
      }
    } catch (err) {
      console.error(err, 'Layout - Gtm, iProspect error');
    }

    if (to.name === 'feed' && window.dataLayer) {
      let eventLog;
      let loginType = new StorageHelper({ id: process.env.VUE_APP_STORAGE_LOGIN_TYPE }).get();
      let userDetails = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_CURRENT_USER_DETAIL,
      }).get();
      if (userDetails) {
        window.dataLayer.push({
          user_id: userDetails.id,
        });
      }
      if (loginType) {
        if (loginType === 'login_phone') {
          eventLog = { event: 'login', login_type: 'Telefon Numarasıyla' };
        } else if (loginType === 'login_first') {
          eventLog = { event: 'firstLogin_forgetPassword' };
        } else if (loginType === 'login_customer') {
          eventLog = { event: 'login', login_type: 'Bayi Koduyla' };
        } else if (loginType === 'login_vkn') {
          eventLog = { event: 'login', login_type: 'Vergi Kimlik Numarasıyla' };
        }
      }
      if (userDetails && !window.dataLayer.find(i => i.event === 'HQ_type')) {
        const { pos_channel, user_type_name, trade_category, trade_sub_category } = userDetails;
        eventLog = {
          event: 'HQ_type',
          pos_channel,
          user_type_name,
          trade_category,
          trade_sub_category,
        };
        if (eventLog) {
          window.dataLayer.push(eventLog);
        }
      }
    }
  }
  pushVirtualPv(dataLayer, from, to) {
    var _self = this;
    let clientInfo = new UserAgentHelper();
    const pos = new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_POS_KEY }).get();
    const loginType = new StorageHelper({ id: process.env.VUE_APP_STORAGE_LOGIN_TYPE }).get();
    let pladis = pos?.partners?.find(i => i.partnerName === 'PLADIS');
    let pladisPartnerCode = pladis?.partnerCode;
    var situationData = {
      event: 'commercial_data',
      user_id: _self.userDetails.id, //S
      user_cluster: _self.userDetails.crm_cluster, //Cluster bilgisi
      user_segment: _self.userDetails.interest_cluster, //Varsa cluster dışındaki segment
      user_gender: !_self.userDetails.gender
        ? 'Bilinmiyor'
        : _self.userDetails.gender == 2
        ? 'Erkek'
        : 'Kadın',
      user_register_date: _self.userDetails.registrationDate
        ? new Date(_self.userDetails.registrationDate).toLocaleDateString('tr')
        : null,
      user_register_date_timestamp: _self.userDetails.registrationDate
        ? new Date(_self.userDetails.registrationDate).getTime()
        : null, //register olunduğu tarihin timestamp değeri number formatında
      user_type: _self.userDetails.user_state['user_type'], //New ya da Returning diyebiliriz. Bizim için new kavramı ilk defa geldiğinde ise new, diğerleri returning olabilir. Ya da son 1 ayda gelenlere New denebilir.
      user_member_duration: _self.userDetails.user_state['user_member_duration'], //kullanıcının üye olduğu süre gün sayısı
      user_hashed_email: _self.userDetails.user_state['user_hashed_email'], //md5 hashed mail basılabilir
      user_location_city: _self.userDetails.city,
      user_new_msg: _self.userDetails.user_state['user_new_msg'], //Kullanıcının okumadığı mesaj sayısı
      user_point: _self.userDetails.pos_tp_point, //Kullanıcının toplam puanı
      user_competition: _self.userDetails.user_state['user_competition'], //Kullanıcının o anda katılmış olduğu yarışma varsa o string formatta ya da array formatta basılabilir
      user_wins: _self.userDetails.user_state['user_wins'], //Kullanıcının kazandığı yarışmalar string formatta ya da array formatta basılabilir
      page_type: to.name, //layoutModel.controllerName, //Sayfa tiplerini yapısal isimlendirebilirsiniz
      site_device_type: clientInfo.device, //Desktop, Mobile, Tablet ya da TV
      site_server:
        document && document.location && document.location.host ? document.location.host : null, //Site farklı serverlar üzerinden çalıştırılıyorsa o serverın bilgisi
      site_browser: clientInfo.browser, //Tarayıcı tipi
      site_os: clientInfo.OS, //İşletim sistemi - Windows, Android, iOS, Linux
      site_os_version: clientInfo.OsVersion, //İşletim sistemi versiyonu
      site_referrer: document.referrer || from.path, //siteye gelinen refferer domaini
      landing_page: '/auth/login/select', //sitede gelinen ilk sayfa
      page_domain: window.location.origin,
      page_language: window.navigator.language,
      page_site_name: 'Pmaktif',
      page_site_region: 'tr',
      page_title: 'PMAKTIF',
      page_url: window.location.href,
      user_pos_channel: _self.userDetails.pos_channel,
      user_status: _self.userDetails.user_type === 1 ? 'bayi' : 'pm_user',
      site_browser_lang: window.navigator.language, //Sitenin açıldığı tarayıcının dili
      New_PMICustomerCode: _self.userDetails.customer_code,
      login_method: loginType,
      site_type: 'mobile',
      visitor_type: 'visitor',
      New_PladisPartnerCode: pladisPartnerCode || null,
      visit_type: _self.userDetails.visit_type,
      trade_program: _self.userDetails.trade_program,
      out_of_stock_product: _self.userDetails.out_of_stock_product,
      handling_product: _self.userDetails.handling_product,
      progressive_sku: _self.userDetails.progressive_sku,
      growth_index: _self.userDetails.growth_index,
      giro_segment: _self.userDetails.giro_segment,
      bayi_location: _self.userDetails.bayi_location,
      money_score: _self.userDetails.money_score,
      puan_score: _self.userDetails.puan_score,
      device_type: clientInfo.device,
      order_currency: 'TRY',
      trade_category: _self.userDetails.trade_category,
      trade_sub_category: _self.userDetails.trade_sub_category,
      //  user_type: _self.userDetails.user_type,
      user_timestemp: new Date().toISOString(),
      'gtm.uniqueEventId': Math.floor(Math.random() * 1000),
    };

    const isImageRecognitionUser = this.userDetails.user_type_name === 'Image Recognition User';

    if (!isImageRecognitionUser) {
      User.getRouteDays().then(res => {
        if (res?.data?.Data) {
          situationData.order_date = String(res?.data?.Data[0]?.routeOrderDays);
        }
      });
    }
    dataLayer.push(situationData);
    this.addGTMStartEvent(dataLayer);
  }
}

export default GtmHelper;
