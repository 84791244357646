<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :aria-labelledby="iconName"
    baseProfile="tiny"
    version="1.2"
    role="presentation"
    :viewBox="`0 0 ${width} ${height}`"
    @click="$emit('click', $event)"
  >
    <component :is="iconComponent" :color="iconColor"></component>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {
      type: String,
      required: true,
    },
    width: {
      type: [Number, String],
      default: 21,
    },
    height: {
      type: [Number, String],
      default: 21,
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
  },
  computed: {
    iconComponent() {
      return () =>
        this.iconName && this.iconName.length
          ? import(`@/components/shared/VueIcon/icons/${this.iconName}.vue`)
          : null;
    },
  },
};
</script>
