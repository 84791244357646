<template>
  <div :class="cssClasses.wrapper">
    <div :class="cssClasses.oval">
      <div :class="cssClasses.logo"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SplashScreen',
  data() {
    return {
      cssClasses: {
        wrapper: [this.$style[`main-wrapper`]],
        oval: [this.$style[`background-oval`]],
        logo: [this.$style[`pm-aktif`]],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.cssClasses.wrapper.push(this.$style[`main-wrapper-loaded`]);
    }, 100);
  },
};
</script>

<style lang="scss" module>
.main-wrapper {
  background: url('~@/assets/images/splashscreen/rectangle.png');
  position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  &-loaded {
    opacity: 1;
  }
}

.background-oval {
  background: url('~@/assets/images/splashscreen/oval.png');
  position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
}
.pm-aktif {
  background: url('~@/assets/images/splashscreen/pm-aktif.png');
  position: fixed;
  background-repeat: no-repeat;
  width: 150px;
  height: 45px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
