export const arrTranspiler = (input, parentScreenCode = null) => {
  const output = {};
  input.forEach(element => {
    const { name, screenCode } = element;
    const objKey = `cs-${element.screenCode}`;

    output[objKey] = {
      name,
      screenCode,
    };

    if (element.children && element.children.length) {
      output[objKey]['children'] = arrTranspiler(element.children, element.screenCode);
    }

    if (parentScreenCode && !isNaN(parseInt(parentScreenCode))) {
      output[objKey]['parentScreenCode'] = parentScreenCode;
    }
  });
  return output;
};

export const getScreenCodes = (input, scArray = []) => {
  input.forEach(element => {
    const { screenCode } = element;
    scArray.push(screenCode);

    if (element.children && element.children.length) {
      getScreenCodes(element.children, scArray);
    }
  });
  return scArray;
};
