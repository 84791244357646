import RouteBase from '@/router/routes/RouteBase.js';

class RoutesOtp {
  subFolderPath = '/otp';
  isPublic = false;
  title = 'Tütün Akademisi';

  Otp = new RouteBase({
    name: 'Otp',
    path: '',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Otp',
    isPublic: this.isPublic,
    screenCode: 22,
    meta: {
      icon: 'otp',
      appbarConfig: {
        hasBackButton: true,
        title: 'Tütün Akademi',
      },
    },
  });
  Learn = new RouteBase({
    name: 'Learn',
    path: ':name/:id',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Learn',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        hasBackButton: true,
        title: 'Tütün Akademi Eğitim',
      },
    },
  });
  Faq = new RouteBase({
    name: 'Sss',
    path: 'sss',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Sss',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        hasBackButton: true,
        title: 'Sık Sorulan Sorular',
      },
    },
  });
}

export default new RoutesOtp();
