export const AuthDefaultState = () => {
  return {
    accessToken: null,
    refreshToken: null,
    user: null,
    userDetails: null,
    selectedPos: null,
    posList: null,
    overflow: 'auto',
    accountType: null,
    afterLogin: false,
    expired: false,
  };
};
