import RouteBase from '@/router/routes/RouteBase.js';

class RoutesLearn {
  subFolderPath = '/learn';
  isPublic = false;

  Home = new RouteBase({
    name: 'learn-navigation',
    path: '',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Home',
    isPublic: this.isPublic,
    meta: {
      icon: 'pieChart',
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Akademi',
      },
    },
  });
  DigitalLiteracy = new RouteBase({
    name: 'DigitalLiteracy',
    path: 'digital-literacy/:contentTypeId?',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'DigitalLiteracy',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Eğitim Videoları',
      },
    },
  });

  TotalLoss = new RouteBase({
    name: 'TotalLoss',
    path: 'total-loss',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'TotalLoss',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Yok Satarak Ne Kadar Kaybediyorsunuz?',
      },
    },
  });
  Turnover = new RouteBase({
    name: 'Turnover',
    path: 'turnover',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Turnover',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Ciro Dağılımınızı Öğrenin',
      },
    },
  });
  Endorsement = new RouteBase({
    name: 'Endorsement',
    path: 'endorsement',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Endorsement',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Kârınızı Hesaplayın',
      },
    },
  });
  EndorsementByCategory = new RouteBase({
    name: 'EndorsementByCategory',
    path: 'endorsement-by-category',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'EndorsementByCategory',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Hangi Üründen Ne Kadar Kâr Ediyorsunuz?',
      },
    },
  });
  VideoDetail = new RouteBase({
    name: 'VideoDetail',
    path: 'video-detail',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'VideoDetail',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Video İçerik',
      },
    },
  });
}

export default new RoutesLearn();
