import RouteBase from '@/router/routes/RouteBase.js';

class RoutesSecure {
  subFolderPath = '';
  isPublic = false;

  Feed = new RouteBase({
    name: 'feed',
    path: 'feed',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Feed',
    pageTitle: 'Ana Sayfa',
    isPublic: this.isPublic,
    meta: {
      startupConfig: {
        setCustomerDetail: true,
      },
      icon: 'cup',
      appbarConfig: {
        hasBackButton: false,
        title: 'Merhaba ',
      },
    },
  });

  Certificates = new RouteBase({
    name: 'certificates',
    path: 'certificates',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Certificates',
    isPublic: this.isPublic,
    meta: {
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Sertifikalar',
        },
      ],
    },
  });

  FeedDetail = new RouteBase({
    name: 'feed-detail',
    path: 'feed/:id',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'FeedDetail',
    isPublic: this.isPublic,
    meta: {
      icon: 'cup',
      appbarConfig: { hasBackButton: true, title: '' },
    },
  });

  PlayAndWin = new RouteBase({
    name: 'play-and-win',
    path: 'play-and-win',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'PlayAndWin',
    isPublic: this.isPublic,
    screenCode: 1,
    meta: {
      icon: 'cup',
      appbarConfig: { hasBackButton: true, title: 'Oyna Kazan' },
    },
  });

  DoAndWin = new RouteBase({
    name: 'do-and-win',
    path: 'do-and-win',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'DoAndWin',
    isPublic: this.isPublic,
    screenCode: 2,
    meta: {
      icon: 'wave',
      appbarConfig: { hasBackButton: true, title: 'Yap Kazan' },
    },
  });

  Surveys = new RouteBase({
    name: 'surveys',
    path: 'surveys',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Surveys',
    isPublic: this.isPublic,
    screenCode: 222,
    meta: {
      icon: 'wave',
      appbarConfig: { hasBackButton: false, title: 'Anketler' },
    },
  });

  Settings = new RouteBase({
    name: 'settings',
    path: 'settings',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Settings',
    isPublic: this.isPublic,
    screenCode: 10,
    meta: {
      icon: 'cog',
    },
  });
  Statics = new RouteBase({
    name: 'statics',
    path: 'statics',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Statics',
    isPublic: this.isPublic,
  });

  BusinessPartners = new RouteBase({
    name: 'business-partners',
    path: 'business-partners',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'BusinessPartners',
    isPublic: this.isPublic,
    meta: {
      icon: 'briefcase',
    },
  });

  ManageMyBusiness = new RouteBase({
    name: 'manage-my-business',
    path: 'manage-my-business',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'ManageMyBusiness',
    isPublic: this.isPublic,
    screenCode: 3,
    meta: {
      icon: 'briefcase',
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Performans Takibi',
      },
    },
  });

  MyWallets = new RouteBase({
    name: 'my-wallets',
    path: 'my-wallets',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'MyWallets',
    isPublic: this.isPublic,
    screenCode: 4,
    meta: {
      icon: 'wallet',
      appbarConfig: {
        hasBackButton: true,
        title: 'Cüzdanlarım',
      },
    },
  });

  Learn = new RouteBase({
    name: 'learn',
    path: 'learn',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Learn',
    isPublic: this.isPublic,
    screenCode: 5,
    meta: {
      icon: 'pieChart',
    },
  });
  Fun = new RouteBase({
    name: 'fun',
    path: 'fun',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Fun',
    pageTitle: 'Yaşam',
    isPublic: this.isPublic,
    screenCode: 6,
    meta: {
      icon: 'magicStick',
      appbarConfig: {
        hasBackButton: true,
        title: 'Yaşam',
        hasSearchBar: true,
        height: 160,
      },
    },
  });

  GiftCatalogue = new RouteBase({
    name: 'gift-catalogue',
    path: 'gift-catalogue',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'GiftCatalogue',
    isPublic: this.isPublic,
    screenCode: 7,
    meta: {
      icon: 'userGuide',
    },
  });

  Campaigns = new RouteBase({
    name: 'campaigns',
    path: 'campaigns',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Campaigns',
    isPublic: this.isPublic,
    screenCode: 8,
    meta: {
      icon: 'tag',
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Kampanyalar',
        hasSearchBar: true,
        collapsedHeight: 160,
      },
    },
  });
  CampaignDetail = new RouteBase({
    name: 'campaign-detail',
    path: 'campaigns/:id',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'CampaignDetail',
    isPublic: this.isPublic,
    meta: {
      icon: 'cup',
      appbarConfig: { hasBackButton: true, title: 'Kampanya Detayı' },
    },
  });

  UserManual = new RouteBase({
    name: 'user-manual',
    path: 'user-manual',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'UserManual',
    isPublic: this.isPublic,
    screenCode: 9,
    meta: {
      icon: 'info',
    },
  });

  VirtualVisit = new RouteBase({
    name: 'virtual-visit',
    path: 'virtual-visit',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'VirtualVisit',
    isPublic: this.isPublic,
    screenCode: 18,
    meta: {
      icon: 'virtualVisitMenu',
      appbarConfig: { disabled: false, hasBackButton: true, title: 'Şef Dükkanında' },
    },
  });

  ImageRecognition = new RouteBase({
    name: 'image-recognition',
    path: 'image-recognition',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'ImageRecognition',
    isPublic: this.isPublic,
    meta: {
      icon: 'wave',
      appbarConfig: {
        hasBackButton: false,
        title: 'Merhaba ',
      },
    },
  });

  Otp = new RouteBase({
    name: 'otp',
    path: 'tutun-akademi',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Otp',
    isPublic: this.isPublic,
    screenCode: 22,
    meta: {
      icon: 'otp',
      appbarConfig: {
        hasBackButton: false,
      },
    },
  });

  Entrepreneurship = new RouteBase({
    name: 'entrepreneurship',
    path: 'entrepreneurship',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Entrepreneurship',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: { isCollapsable: false, hasBackButton: true },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Entrepreneurship',
        },
      ],
    },
  });

  ContactGeneral = new RouteBase({
    name: 'contact',
    path: 'geribildirim',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Contact',
    isPublic: this.isPublic,
    screenCode: 21,
    meta: {
      icon: 'contact',
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Ürün Geri Bildirimi',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/',
        },
        {
          name: 'Ürün Geri Bildirimi',
        },
      ],
    },
  });

  Chatbot = new RouteBase({
    name: 'chatbot',
    path: 'sef-cevaplasin',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Chatbot',
    isPublic: this.isPublic,
    screenCode: 23,
    meta: {
      icon: 'headphoneChef',
      appbarConfig: {
        hasBackButton: true,
        title: 'Sen Sor Şef Cevaplasın',
      },
    },
  });
  Player = new RouteBase({
    name: 'player',
    path: 'player',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Player',
    isPublic: this.isPublic,
    meta: {
      icon: 'info',
      appbarConfig: {
        title: 'Video Player',
        hasBackButton: true,
      },
    },
  });

  BeyondTime = new RouteBase({
    name: 'beyondtime',
    path: 'beyond-time',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'BeyondTime',
    isPublic: this.isPublic,
    screenCode: 25,
    meta: {
      icon: 'iconBeyondTime',
      appbarConfig: {
        hasBackButton: true,
        title: 'Zamanın Ötesinde',
      },
    },
  });

  NotFound = new RouteBase({
    name: 'not-found-secure',
    path: '*',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'NotFound',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {},
    },
  });

  MyAgreements = new RouteBase({
    name: 'agreements',
    path: 'agreements',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'MyAgreements',
    isPublic: this.isPublic,
    screenCode: 24,
    meta: {
      icon: 'iconAgreement',
      appbarConfig: { hasBackButton: true, title: 'Puan Kriterlerim' },
    },
  });
  MySurveys = new RouteBase({
    name: 'all-my-surveys',
    path: 'my-surveys',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'MySurveys',
    isPublic: this.isPublic,
    screenCode: 27,
    meta: {
      icon: 'iconSurvey',
      appbarConfig: { hasBackButton: true, title: 'Tüm Anketler' },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Tüm Anketler',
          link: '/secure/mysurveys',
        },
      ],
    },
  });
  Trendyol = new RouteBase({
    name: 'trendyol',
    path: 'trendyol',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Trendyol',
    isPublic: this.isPublic,
    screenCode: 26,
    meta: {
      icon: 'iconTrendyol',
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Trendyol Cüzdan',
        },
      ],
    },
  });

  TrendyolPlayer = new RouteBase({
    name: 'trendyol-player',
    path: 'trendyol-player',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'TrendyolPlayer',
    isPublic: this.isPublic,
    meta: {
      icon: 'iconTrendyol',
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Trendyol Video',
        },
      ],
    },
  });

  EmergencyTrainings = new RouteBase({
    name: 'emergency-trainings',
    path: 'emergency-trainings',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'EmergencyTrainings',
    pageTitle: 'Emergency Trainings',
    isPublic: this.isPublic,
    meta: {
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Emergency Trainings',
        },
      ],
    },
  });

  Playable = new RouteBase({
    name: 'playable',
    path: 'playable',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Playable',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: { isCollapsable: false, hasBackButton: true },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Playable',
        },
      ],
    },
  });

  WeeklyPlayable = new RouteBase({
    name: 'weekly-playable',
    path: 'weekly-playable',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'WeeklyPlayable',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: { isCollapsable: false, hasBackButton: true },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'WeeklyPlayable',
        },
      ],
    },
  });

  VideoSeries = new RouteBase({
    name: 'video-series',
    path: 'video-series',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'VideoSeries',
    pageTitle: 'Video Serileri',
    isPublic: this.isPublic,
    meta: {
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Video Serileri',
        },
      ],
    },
  });
}

export default new RoutesSecure();
