import { render, staticRenderFns } from "./BrandErrorModal.vue?vue&type=template&id=557e6977&scoped=true&"
import script from "./BrandErrorModal.vue?vue&type=script&lang=js&"
export * from "./BrandErrorModal.vue?vue&type=script&lang=js&"
import style0 from "./BrandErrorModal.vue?vue&type=style&index=0&module=true&lang=scss&"
import style1 from "./BrandErrorModal.vue?vue&type=style&index=1&id=557e6977&scoped=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "557e6977",
  null
  
)

export default component.exports