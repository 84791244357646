import RouteBase from '@/router/routes/RouteBase.js';

class RoutesUserManual {
  subFolderPath = '/userManual';
  isPublic = false;
  title = 'Kullanım Kılavuzu';

  Navigation = new RouteBase({
    name: 'user-manual-navigation',
    path: '',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'UserManualNavigation',
    isPublic: this.isPublic,
    meta: {
      icon: 'info',
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: `${this.title}`,
      },
    },
  });

  Detail = new RouteBase({
    name: 'user-manual-detail',
    path: 'detail/:id?',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'UserManualDetail',
    isPublic: this.isPublic,
    meta: {
      hideBottomBar: true,
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: `${this.title}`,
      },
    },
  });
}

export default new RoutesUserManual();
